import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { BlogPost } from "src/@omnial/_models/catalog/blog.model";
import { RepositorySearchService } from "../repository-search.service";

@Injectable()
export class BlogSearchService implements OnDestroy {

  private subscriptions: Subscription[] = [];

  constructor(public repoSearchService: RepositorySearchService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

 public searchBlogPosts(term: string): Observable<BlogPost[]> {
    return new Observable((observer) => {
      this.subscriptions.push(this.repoSearchService.searchBlogPosts(term).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        }
      }));
    });
  }
}

