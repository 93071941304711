import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { Blog } from "src/@omnial/_models/catalog/blog.model";
import { SevenSpikesService } from "./seven.spikes.service";

@Injectable()
export class BlogService implements OnDestroy {
  public blog: BehaviorSubject<Blog> = new BehaviorSubject<Blog>(null);
  private $blog: Blog = null;
  private subscriptions: Subscription[] = [];

  constructor(public sevenSpikesService: SevenSpikesService) { }

  public load(init?: Blog): void {
    if (init) {
      this.$blog = init;
      this.blog.next(this.$blog);
      return;
    }
    if (!this.$blog) {
      this.subscriptions.push(this.sevenSpikesService.getBlog().subscribe({
        next: (res: Blog) => {
          this.$blog = res;
          this.blog.next(this.$blog);
        },
        error: () => {
          this.$blog = null;
          this.blog.next(this.$blog);
        }
      }));
    } else {
      this.blog.next(this.$blog);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
